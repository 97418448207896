import { MenuOutlined, InfoCircleOutlined as AntdInfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, Col, Row, Typography, Button, Badge } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CubejsIcon } from '../../shared/icons';
import { useSecurityContext } from '../../hooks';
import jwtDecode from 'jwt-decode';

import { StyledMenu, StyledMenuItem } from './Menu';
import { UserAvatar } from './UserAvatar';
import { glossaryURL } from '../../config';

const { Title } = Typography;

const StyledHeader = styled(Layout.Header)`
  && {
    background-color: var(--dark-02-color);
    color: white;
    padding: 0 16px;
    line-height: 44px;
    border-bottom: 0.3em solid;
    border-bottom-color: var(--yellow-color);
    display: flex;
    flexDirection: row;
    justifyContent: center;
    alignItems: center;
  }
`;

export default function Header({ selectedKeys }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 992px)',
  });

  const isMobileOrTable = useMediaQuery({
    query: '(max-device-width: 991px)',
  });

  const { isLoggedIn, currentToken } = useSecurityContext();

  return (
    <StyledHeader>
      <Col style={{ float: 'left'}}>
      <Link to={{
        pathname: "/",
        search: "?query={}",
      }}>
        <Row 
            justify="space-evenly"
            align="middle"
            gutter={24}
            style={{ marginLeft: 2, marginRight: 4}}
          >
          <CubejsIcon/> 
          <Title level={2} style={{color: 'white', marginTop: 10, marginLeft: 10, marginRight: 16}}>Exodus</Title>
        </Row>
        </Link>
      </Col>
      {isLoggedIn && isDesktopOrLaptop && (
        <StyledMenu theme="light" mode="horizontal" selectedKeys={selectedKeys}>
          <StyledMenuItem key="/">
            <Link to="/?query={}" style={{color: 'white'}}>Explore</Link>
          </StyledMenuItem>
          {['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department)  && <StyledMenuItem key="/audience">
            <Link to="/audience" style={{color: 'white'}}>Audience Builder</Link>
          </StyledMenuItem>}
          {['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department) && <StyledMenuItem key="/pipelines">
            <Link to="/pipelines" style={{color: 'white'}}>Dashboard Pipelines</Link>
          </StyledMenuItem>}
          <StyledMenuItem key="/dashboards/article">
            <Link to='/dashboards/article?article=""' style={{color: 'white'}}>Dashboards</Link>
          </StyledMenuItem>
          
          

          {/* <StyledMenuItem key="/schema">
            <Link to="/schema">Schema</Link>
          </StyledMenuItem> */}

        </StyledMenu>
      )}

      {isLoggedIn && isMobileOrTable && (
        <div style={{ float: 'right' }}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="/">
                  <Link to="/?query={}">Explore</Link>
                </Menu.Item>
                {['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department) && <Menu.Item key="/pipelines">
                  <Link to="/pipelines">Dashboard Pipeline</Link>
                </Menu.Item>}
                <Menu.Item key="/dashboards/article">
                  <Link to="/dashboards/article">Dashboards</Link>
                </Menu.Item>
               
                {['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department)  && <Menu.Item key="/audience">
                  <Link to="/audience">Audience Builder</Link>
                </Menu.Item>}
              </Menu>
            }
          >
            <MenuOutlined />
          </Dropdown>
        </div>
      )}
      {isLoggedIn &&isDesktopOrLaptop &&<Row style={{ marginLeft: 'auto', marginTop: 6}}>
        <Button 
          type="secondary" 
          shape="round"
          icon={<AntdInfoCircleOutlined />} 
          style={{ marginTop: 6, marginRight: 24}}
          onClick={() => window.open('https://docs.google.com/presentation/d/1SMiHk5mrZeG5WDtWNM6C6UXoSknCwJVhcCfylT7uKwM/edit#slide=id.p', "_blank")}
        > 
          Training Slides 
        </Button>
        <Button 
          type="primary" 
          shape="round"
          icon={<AntdInfoCircleOutlined />} 
          style={{ marginTop: 6, marginRight: 24}}
          onClick={() => window.open(glossaryURL, "_blank")}
        > 
          Exodus Glossary 
        </Button>
        <UserAvatar />
      </Row>}
    </StyledHeader>
  );
}