
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const { Sider } = Layout;

export default function CustomSider({dashboards}) {
    const { push } = useHistory();
    const { pathname } = useLocation();
    const [activeDash, setActiveDash] = useState('article');

    useEffect(() => {
        setActiveDash(pathname.split('/')[pathname.split('/').length-1])
    }, [pathname])
    
    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {
                console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
            }}
        >
            <div className="logo" />
            <Menu
                theme="light"
                mode="inline"
                selectedKeys={[dashboards.filter(dash => dash['path'] === activeDash)[0]['key']]}
                style={{
                    height: '100%',
                    borderRight: 0,

                }}
                onClick={({ keyPath }) => push(`/dashboards/${dashboards[keyPath-1].path}`)}
                items={dashboards.map(
                    (dash, index) => ({
                        key: String(index + 1),
                        icon: React.createElement(dash.icon),
                        label: dash.name,

                    }),
                )}
            />
        </Sider>
    );
}