import React, { useEffect, useState } from 'react';
import { playgroundContext, buildApiUrl, platformBEtoFE } from '../config';
import { Space, Row, Col, Spin, Skeleton, Descriptions, Typography, Button, Collapse, Statistic as AntStatistic, Input, Tooltip } from 'antd';
import { Card, Button as CustomButton, Alert} from '../atoms';

import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { CategoryScale, LinearScale, Title as ChartTitle, Tooltip as ChartTooltip, Legend, BarElement, Chart as ChartJS } from "chart.js";
import { commonOptions, tickFormatting } from '../components/ChartContainer/chartConfigs';
import { getCountryChartData, getSourceChartData, getPlatformChartData, getDailyPageviewsChartData } from '../components/Dashboards/ArticleDashboard/widgets';
import { 
  getDrupalQuery, getArticleGAQuery, getArticleGARegUsersQuery, getArticleUnionQuery, getArticleDayGAQuery, getArticleDayUnionQuery, 
  getArticleDayGARegUsersQuery, getArticleCountryUnionQuery, getArticlePlatformUnionQuery, getArticleTrafficSourceUnionQuery, getArticleDailyPageviewsQuery, 
  getExploreArticleCountryUnionQuery, getExploreArticlePlatformUnionQuery, getExploreArticleTrafficSourceUnionQuery, getExploreArticleDailyPageviewsQuery, 
} from '../components/Dashboards/ArticleDashboard/queries';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { findSecondLargest, isValidUrl, makePercentage, copyToClipboard } from '../utils';
import  { SearchOutlined, PieChartOutlined, LinkOutlined, AreaChartOutlined, BarChartOutlined } from '@ant-design/icons';
import { useCubeQuery } from '@cubejs-client/react';
import { QueryContext } from '../components/QueryContext';
import { useSecurityContext } from '../hooks';
import { useMetaContext } from '../components/MetaContext';
import { AboutData } from '../components/Dashboards/AboutData';
import SectionRow  from '../components/SectionRow';


const { Title, Link, Text } = Typography;
const { Panel } = Collapse;
const { Search } = Input;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  ChartTooltip,
  Legend,
  ChartDataLabels
);


const StyledCard = styled(Card)`
  margin: 36px 0px 0px;
  ${'' /* width: 100%; */}
  &.ant-card-head {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    border-bottom-color: #f6f6f8;
  }
  &ant-card-body {
    max-width: 100%;
    overflow: auto;
    position: relative;
  }
  .ant-tabs > .ant-tabs-nav {
    margin-bottom: 8px;
  }
`;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: var(--dark-02-color);
    margin-bottom: 4px;
  }
`;

const StyledText = styled(Text)`
  &.ant-typography {
    color: var(--dark-02-color);
    margin-bottom: 4px;
  }
`;

const Statistic = styled(AntStatistic)`
  &.ant-statistic-title {
    font-size: 36px;
    margin-bottom: 4px;
  }
`


export function ArticleDashboardPage() {
  
  const [hasResults, setHasResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // In the order
  const [tempSearchValue, setTempSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchEntered, setSearchEntered] = useState(false);
  const [articleID, setArticleID] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [limit, setLimit] = useState(1);
  
  const [atAGlanceTabKey, setAtAGlanceTabKey] = useState('main');
  const { currentToken, getUserDepartment } = useSecurityContext();
  const location = useLocation();
  const { push } = useHistory();
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const department = getUserDepartment();

  const { meta, isLoading: isMetaLoading } = useMetaContext();
  const [metaExtended, setMetaExtended] = useState([]);

  // queries
  const [drupalQuery, setDrupalQuery] = useState(getDrupalQuery('', limit));
  const renderDrupal = useCubeQuery(drupalQuery, {skip: articleID.length === 0});

  // At A Glance
  const [articleGAQuery, setArticleGAQuery] = useState(getArticleGAQuery('', limit));
  const renderArticleGA = useCubeQuery(articleGAQuery, {skip: articleID.length === 0});

  const [articleGARegUsersQuery, setArticleGARegUsersQuery] = useState(getArticleGARegUsersQuery('', limit));
  const renderArticleGARegUsers = useCubeQuery(articleGARegUsersQuery, {skip: articleID.length === 0});

  const [articleUnionQuery, setArticleUnionQuery] = useState(getArticleUnionQuery('', limit));
  const renderArticleUnion = useCubeQuery(articleUnionQuery, {skip: articleID.length === 0});

  const [articleDayGAQuery, setArticleDayGAQuery] = useState(getArticleDayGAQuery('', limit, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]));
  const renderArticleDayGA = useCubeQuery(articleDayGAQuery, {skip: articleID.length === 0});
  
  const [articleDayUnionQuery, setArticleDayUnionQuery] = useState(getArticleDayUnionQuery('', limit, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]));
  const renderArticleDayUnion = useCubeQuery(articleDayUnionQuery, {skip: articleID.length === 0});

  const [articleDayGARegUsersQuery, setArticleDayGARegUsersQuery] = useState(getArticleDayGARegUsersQuery('', limit, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]));
  const renderArticleDayGARegUsers = useCubeQuery(articleDayGARegUsersQuery, {skip: articleID.length === 0});

  // Other queries
  const [articleCountryUnionQuery, setArticleCountryUnionQuery] = useState(getArticleCountryUnionQuery('', limit));
  const renderArticleCountryUnion = useCubeQuery(articleCountryUnionQuery, {skip: articleID.length === 0});

  const [articlePlatformUnionQuery, setArticlePlatformUnionQuery] = useState(getArticlePlatformUnionQuery('', limit));
  const renderArticlePlatformUnion = useCubeQuery(articlePlatformUnionQuery, {skip: articleID.length === 0});

  const [articleTrafficSourceUnionQuery, setArticleTrafficSourceUnionQuery] = useState(getArticleTrafficSourceUnionQuery('', limit));
  const renderArticleTrafficSourceUnion = useCubeQuery(articleTrafficSourceUnionQuery, {skip: articleID.length === 0});

  const [articleDailyPageviewsQuery, setArticleDailyPageviewsQuery] = useState(getArticleDailyPageviewsQuery('', limit));
  const renderArticleDailyPageviews = useCubeQuery(articleDailyPageviewsQuery, {skip: articleID.length === 0});
  
  //BQ data
  const [drupalMetadata, setDrupalMetadata] = useState({});
  const [articleUsers, setArticleUsers] = useState(null);
  const [articleLoyRegUsers, setArticleLoyRegUsers] = useState(null);
  const [articlePageviews, setArticlePageviews] = useState(null);
  const [articleExitRate, setArticleExitRate] = useState(null);
  const [articleTimeOnPage, setArticleTimeOnPage] = useState(null);
  const [articleFiftyCompletions, setArticleFiftyCompletions] = useState(null);
  const [articleHundredCompletions, setArticleHundredCompletions] = useState(null);
  const [articleComments, setArticleComments] = useState(null);
  const [articleNewSubscribers, setArticleNewSubscribers] = useState(null);
  const [articleActiveSubscribers, setArticleActiveSubscribers] = useState(null);

  const [articleCountryUsersData, setArticleCountryUsersData] = useState([]);
  const [articleCountryUsersLabels, setArticleCountryUsersLabels] = useState([]);
  const [articlePlatformUsersData, setArticlePlatformUsersData] = useState([]);
  const [articlePlatformUsersLabels, setArticlePlatformUsersLabels] = useState([]);
  const [articleTrafficSourceUsersData, setArticleTrafficSourceUsersData] = useState([]);
  const [articleTrafficSourceUsersLabels, setArticleTrafficSourceUsersLabels] = useState([]);
  const [articleDailyPageviewsData, setArticleDailyPageviewsData] = useState([]);
  const [articleDailyPageviewsLabels, setArticleDailyPageviewsLabels] = useState([]);


  // USE EFFECTS
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let query = '';
    try{ 
      query = JSON.parse(params.get('article') || '');
    } catch(e) { 
      console.log('Bad query passed!')
    }
    setSearchValue(query);
    setTempSearchValue(query);
    setQueryParam({query: query});
  }, [])

  useEffect(() => {
    if(meta && meta.length  > 0){
      setMetaExtended(meta)
    }
  }, [isMetaLoading])

  useEffect(() => {
    if(isValidUrl(searchValue)){
      // URL is given, we extract Article ID
      const parsed = new URL(searchValue);
      const path = parsed.pathname.split('/');
      const indexArticle = path.indexOf('article') + 1
      if (path[indexArticle]){
        
        if (articleID === path[indexArticle]){
          setLimit(limit === 1 ? 2 : 1)
        } else {
          setArticleID(path[indexArticle]);
        }
      } else {
        console.log('Must not be valid', searchValue)
        setHasResults(false);
      }
    } else if(searchValue && Number.isInteger(Number(searchValue))){
      // article given directly
      if (articleID === searchValue){
        setLimit(limit === 1 ? 2 : 1)
      } else {
        setArticleID(searchValue);
      }

    } else {
      console.log('Must not be valid', searchValue)
      setHasResults(false);
    }
  }, [searchValue, searchEntered])

  useEffect(() => {
    console.log(articleID)
    if (articleID.length > 0){
      setHasResults(false);
      setIsLoading(true)
      updateCubeQueries(articleID, limit)
    }
  }, [articleID, limit])

  // Pass a date Range to day based queries
  useEffect(() => {
    if (articleID.length > 0 && moment(drupalMetadata['Drupal.publication_date']).isValid()){
      const dateRange = [
        moment(drupalMetadata['Drupal.publication_date']).format("YYYY-MM-DD"),
        moment(drupalMetadata['Drupal.publication_date']).add(90, "days").format("YYYY-MM-DD"),  
      ]
      setDateRange(dateRange);
      updateDayBasedCubeQueries(articleID, limit, dateRange)
    }
  }, [drupalMetadata])

  useEffect(() => {
    // console.log(renderDrupal)
    if('resultSet' in renderDrupal && renderDrupal.resultSet){
      setIsLoading(false)
      const data = renderDrupal.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setHasResults(true)
          setDrupalMetadata(data[0])
        }
    }
  }, [renderDrupal.resultSet, renderDrupal.isLoading])

  // useEffect(() => {
  //   console.log(drupalMetadata)
  // }, [drupalMetadata])

  useEffect(() => {
    if('resultSet' in renderArticleUnion && renderArticleUnion.resultSet){
      const data = renderArticleUnion.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleUsers(data[0]['ArticleAppleGAUnion.users'])
        }
    }
  }, [renderArticleUnion.resultSet, renderArticleUnion.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleGA && renderArticleGA.resultSet){
      const data = renderArticleGA.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleLoyRegUsers(data[0]['ArticleGA.loy_reg_users'])
        }
    }
  }, [renderArticleGA.resultSet, renderArticleGA.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleDayGA && renderArticleDayGA.resultSet){
      const data = renderArticleDayGA.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleExitRate(data[0]['ArticleDayGA.exit_rate'])
          setArticleFiftyCompletions(data[0]['ArticleDayGA.article_pageviews_fifty_percent'])
          setArticleHundredCompletions(data[0]['ArticleDayGA.article_pageviews_hundred_percent'])
        }
    }
  }, [renderArticleDayGA.resultSet, renderArticleDayGA.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleDayUnion && renderArticleDayUnion.resultSet){
      const data = renderArticleDayUnion.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticlePageviews(data[0]['ArticleDayAppleGAUnion.pageviews'])
          setArticleTimeOnPage(data[0]['ArticleDayAppleGAUnion.time_on_page'])
        }
    }
  }, [renderArticleDayUnion.resultSet, renderArticleDayUnion.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleDayGARegUsers && renderArticleDayGARegUsers.resultSet){
      const data = renderArticleDayGARegUsers.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleComments(data[0]['ArticleDayGARegisteredUsers.comments'] ? data[0]['ArticleDayGARegisteredUsers.comments']: 0)
          setArticleNewSubscribers(data[0]['ArticleDayGARegisteredUsers.new_subscribers'] ? data[0]['ArticleDayGARegisteredUsers.new_subscribers']: 0)
        }
    }
  }, [renderArticleDayGARegUsers.resultSet, renderArticleDayGARegUsers.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleGARegUsers && renderArticleGARegUsers.resultSet){
      const data = renderArticleGARegUsers.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleActiveSubscribers(data[0]['ArticleGARegisteredUsers.active_subscribers'])
        }
    }
  }, [renderArticleGARegUsers.resultSet, renderArticleGARegUsers.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleCountryUnion && renderArticleCountryUnion.resultSet){
      const data = renderArticleCountryUnion.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleCountryUsersData(data.slice(0, 10).map(el => el['ArticleCountryAppleGAUnion.users']))
          setArticleCountryUsersLabels(data.slice(0, 10).map(el => el['ArticleCountryAppleGAUnion.country']))
        }
    }
  }, [renderArticleCountryUnion.resultSet, renderArticleCountryUnion.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticlePlatformUnion && renderArticlePlatformUnion.resultSet){
      const data = renderArticlePlatformUnion.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticlePlatformUsersData(data.slice(0, 10).map(el => el['ArticlePlatformAppleGAUnion.users']))
          setArticlePlatformUsersLabels(data.slice(0, 10).map(el => platformBEtoFE[el['ArticlePlatformAppleGAUnion.platform']]))
        }
    }
  }, [renderArticlePlatformUnion.resultSet, renderArticlePlatformUnion.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleTrafficSourceUnion && renderArticleTrafficSourceUnion.resultSet){
      const data = renderArticleTrafficSourceUnion.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleTrafficSourceUsersData(data.slice(0, 10).map(el => el['ArticleTrafficSourceAppleGAUnion.users']))
          setArticleTrafficSourceUsersLabels(data.slice(0, 10).map(el => el['ArticleTrafficSourceAppleGAUnion.traffic_source']))
        }
    }
  }, [renderArticleTrafficSourceUnion.resultSet, renderArticleTrafficSourceUnion.isLoading])


  useEffect(() => {
    if('resultSet' in renderArticleDailyPageviews && renderArticleDailyPageviews.resultSet){
      const data = renderArticleDailyPageviews.resultSet.loadResponse.results[0].data;
        if (data.length > 0){
          setArticleDailyPageviewsData(data.map(el => el['ArticleDayAppleGAUnion.pageviews']))
          setArticleDailyPageviewsLabels(data.map(el => el['ArticleDayAppleGAUnion.date']))
        }
    }
  }, [renderArticleDailyPageviews.resultSet, renderArticleDailyPageviews.isLoading])


  // METHODS
  const onAtAGlanceTabChange = (key) => {
    setAtAGlanceTabKey(key);
  };

  function setQueryParam({ query }) {
    push({ search: `?article=${encodeURIComponent(JSON.stringify(query))}` });
  }

  const onSearch = (value) => {
    setQueryParam({query: value});
    setSearchEntered(!searchEntered);
    setSearchValue(value);
    // https://www.scmp.com/news/china/article/3252342/mainland-china-coastguard-vows-regular-patrols-waters-where-2-fishermen-died-after-taiwan-coastguard?module=top_story&pgtype=homepage
  }

  const updateCubeQueries = (articleID, limit) => {
    setDrupalQuery(getDrupalQuery(articleID, limit))
    setArticleUnionQuery(getArticleUnionQuery(articleID, limit))
    setArticleGAQuery(getArticleGAQuery(articleID, limit))
    setArticleGARegUsersQuery(getArticleGARegUsersQuery(articleID, limit))
    setArticleCountryUnionQuery(getArticleCountryUnionQuery(articleID, limit === 1 ? 10 : 11))
    setArticlePlatformUnionQuery(getArticlePlatformUnionQuery(articleID, limit === 1 ? 10 : 11))
    setArticleTrafficSourceUnionQuery(getArticleTrafficSourceUnionQuery(articleID, limit === 1 ? 10 : 11))
  }

  const updateDayBasedCubeQueries = (articleID, limit, dateRange) => {
    setArticleDayGAQuery(getArticleDayGAQuery(articleID, limit === 1 ? 1000 : 1001, dateRange))
    setArticleDayUnionQuery(getArticleDayUnionQuery(articleID, limit === 1 ? 1000 : 1001, dateRange))
    setArticleDayGARegUsersQuery(getArticleDayGARegUsersQuery(articleID, limit === 1 ? 1000 : 1001, dateRange))
    setArticleDailyPageviewsQuery(getArticleDailyPageviewsQuery(articleID, limit === 1 ? 1000: 1001, dateRange))
  }
  

  const atAGlanceContentList = ({gaLoading, unionLoading, gaDayLoading, unionDayLoading, ruLoading, ruDayLoading}) => ({
    main: <Row style={{margin: 16}}>
            <Col span={4}><Statistic title="Users" value={articleUsers} loading={unionLoading}/></Col>
            <Col span={5}><Statistic title="Pageviews" value={articlePageviews} loading={unionDayLoading}/></Col>
            <Col span={5}><Statistic title="Regular & Loyal Users" value={articleLoyRegUsers} loading={gaLoading}/></Col>
            <Col span={5}><Statistic title="Active Subscribers" value={articleActiveSubscribers} loading={ruLoading}/></Col>
            <Col span={5}><Statistic title="New Subscribers" value={articleNewSubscribers} loading={ruDayLoading} /></Col>
          </Row>,
    engagement: <Row style={{margin: 16}}>
                  <Col span={4}><Statistic title="Exit Rate" value={makePercentage(articleExitRate, 1)} loading={gaDayLoading} /></Col>
                  <Col span={5}><Statistic title="Avg Time On Page (in seconds)" value={articleTimeOnPage} loading={unionDayLoading}/></Col>
                  <Col span={5}><Statistic title="Article 50% Completed" value={articleFiftyCompletions} loading={gaDayLoading} /></Col>
                  <Col span={5}><Statistic title="Article 100% Completed" value={articleHundredCompletions} loading={gaDayLoading}/></Col>
                  <Col span={5}><Statistic title="Number of Comments" value={articleComments} loading={ruDayLoading}/></Col>
                </Row>,
  });

  const atAGlanceTabList = [
    {
      key: 'main',
      tab: 'Key KPIs',
    },
    {
      key: 'engagement',
      tab: 'Engagement Metrics',
    },
  ];


  const Extra = ({query_obj}) => (
    <SectionRow>
      <Tooltip title="Open in Explore">
        <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}} href={`https://exodus-cube.scmp.com/app/?query=${query_obj}`}>
        <Button 
          type="secondary" 
          shape="round" 
          icon={<SearchOutlined />} 
        />
        </Link>
      </Tooltip>
    </SectionRow>
  );


  return (
    
      <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', marginTop: 36}}>
          <Row align='middle' justify="space-between" style={{width: '100%'}}>
            <Col span={18} offset={3} type="flex">
              <Space direction='horizontal' size='large' style={{display: 'flex', justifyContent: "space-between"}}>
                <StyledTitle>Article Dashboard</StyledTitle>
                <Space direction="horizontal">
                  <AboutData
                    description={`- **sources**: Drupal, Google Analytics, Apple News
- **freshness**:
	- Expect 1 hour before a newly published article becomes searchable
	- Metrics refreshed every 20min, for up to 90 days after publication
- **metrics**:
  - GA + Apple News includes Users (total and splits), Pageviews and Time On Page
  - GA only includes loyal regular users, active subscribers, new subscribers, comments, completion pvs, exit rate`}
                      />
                  <Tooltip title="Copy Dashboard Link">
                  <Button 
                    type="secondary" 
                    shape="round" 
                    icon={<LinkOutlined />} 
                    onClick={async () => {
                      await copyToClipboard(window.location.href, 'The url has been copied');
                    }}
                  />
                  </Tooltip>
                </Space>
              </Space>
              
            </Col>
          </Row>
          <Row align='middle' justify="space-between" style={{width: '100%', marginTop: 12}}>
            
            <Col span={18} offset={3}>
              <Search 
                size="large" 
                placeholder="Insert Article URL (or Article ID)"
                onChange={(e) => setTempSearchValue(e.target.value)}
                onSearch={onSearch}
                value={tempSearchValue}
                allowClear
                loading={isLoading}
              />
              </Col>
              
          </Row>
        </div>
        {!searchValue && <Col span={18} offset={3} style={{marginTop: 60, marginBottom: 720}}>
          <StyledCard title=''>
            <div style={{display: 'flex', marginTop: 120, marginBottom: 120, justifyContent: 'center'}}>
            <StyledTitle level={2}>
              Insert the article's URL in the search bar above ☝️
            </StyledTitle>
            </div>
          </StyledCard>
        </Col>}
        {searchValue && isLoading && <Col span={18} offset={3} style={{marginTop: 60, marginBottom: 720}}>
          <StyledCard title=''>
            <div style={{display: 'flex', marginTop: 120, marginBottom: 120, justifyContent: 'center'}}>
            <Spin size="large"/>
            </div>
          </StyledCard>
        </Col>}
        {searchValue && !isLoading && !hasResults && <Col span={18} offset={3} style={{marginTop: 60, marginBottom: 720}}>
          <StyledCard title=''>
            <Space direction='vertical' size={'large'} style={{marginTop: 90, marginBottom: 90, marginLeft: 60}}>
            <Space size='large'>
            <StyledTitle level={2}>
              404
            </StyledTitle>
            <StyledTitle level={2}>
              Not Found 
            </StyledTitle>
            </Space>
            <StyledTitle level={3}>
              The page you were looking for appears to have moved or never existed... 🤔
            </StyledTitle>
            </Space>
          </StyledCard>
        </Col>}

        {hasResults && <Col span={18} offset={3} style={{marginTop: 60}}>
          <StyledCard title=''>
            <Row gutter={32}>
                <Col span={6}>
                  <img alt="example" style={{width: '100%', maxWidth: 320}}
                    src={drupalMetadata['DrupalArticleImages.image_url']}
                    />
                </Col>
                <Col span={16}>
                    <Space direction='vertical' size={'large'}>
                      <Link href={drupalMetadata['Drupal.url']} target="_blank" style={{fontSize: 20, minWidth: 100}}>
                        {drupalMetadata['Drupal.title']}
                      </Link>
                      <Descriptions title="">
                        <Descriptions.Item label="Author" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.authors_name']}
                        </Descriptions.Item>
                        <Descriptions.Item label="Published" labelStyle={{fontWeight: 580}}>{moment(drupalMetadata['Drupal.publication_date']).format('lll')}</Descriptions.Item>
                        <Descriptions.Item label="Sections" labelStyle={{fontWeight: 580}}>{drupalMetadata['Drupal.sub_sections']}</Descriptions.Item>
                        <Descriptions.Item label="Topics" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.topics']}
                        </Descriptions.Item>
                        <Descriptions.Item label="User Need" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.user_needs'] ? drupalMetadata['Drupal.user_needs'] : "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Keywords" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.keywords'] ? drupalMetadata['Drupal.keywords'].substring(0, 120).concat('...') : "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Paywall Status" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.content_lock']}
                        </Descriptions.Item>
                        <Descriptions.Item label="Word count" labelStyle={{fontWeight: 580}}>
                        {drupalMetadata['Drupal.word_count']}
                        </Descriptions.Item>
                      </Descriptions>
                    </Space>
                </Col>
            </Row>
          </StyledCard>
          <StyledCard 
            title='At a glance'
            // extra={<Extra data={[]} columns={[]} />}
            tabList={atAGlanceTabList}
            activeTabKey={atAGlanceTabKey}
            onTabChange={(key) => {
              onAtAGlanceTabChange(key);
            }}
          >
              
              {atAGlanceContentList({
                gaLoading: renderArticleGA.isLoading, 
                unionLoading: renderArticleUnion.isLoading, 
                gaDayLoading: renderArticleDayGA.isLoading, 
                unionDayLoading: renderArticleDayUnion.isLoading, 
                ruLoading: renderArticleGARegUsers.isLoading,
                ruDayLoading: renderArticleDayGARegUsers.isLoading,
              })[atAGlanceTabKey]}
          </StyledCard>
          <Row>
              <Col span={12}>
              <StyledCard 
                title='Where is this being read?'
                style={{marginRight: 18}}
                extra={<Extra query_obj={encodeURIComponent(JSON.stringify(getExploreArticleCountryUnionQuery(articleID, dateRange)))} />}
              >
              
                  {renderArticleCountryUnion.isLoading ? <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                            <BarChartOutlined
                                                              style={{
                                                                fontSize: 40,
                                                                color: '#bfbfbf',
                                                              }}
                                                            />
                                                          </Skeleton.Node></div> : 
                    <Bar 
                      options={{
                        scales: {
                          x: { display: false },
                          y: {
                            grid: {
                              display: false
                            }
                          }
                        },
                        indexAxis: 'y',
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          title: {
                            display: true,
                            text: 'Users per country',
                          },
                          legend: {display: false},
                          datalabels: {
                            display: true,
                            color: "black",
                            formatter: (value, ctx) => {return (value).toLocaleString('en-US')},
                            anchor: "center",
                            offset: 16,
                            align: "end",
                            font: {
                              size: 14
                            },
                          }
                        },
                      }} 
                      data={getCountryChartData(articleCountryUsersData, articleCountryUsersLabels)}
                      type='bar'
                      height={300}
                    />
                  }
              </StyledCard>
              <StyledCard 
                title='How did readers find this?'
                style={{marginRight: 18}}
                extra={<Extra query_obj={encodeURIComponent(JSON.stringify(getExploreArticleTrafficSourceUnionQuery(articleID, dateRange)))} />}
              >
                  {renderArticleTrafficSourceUnion.isLoading ? <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                            <BarChartOutlined
                                                              style={{
                                                                fontSize: 40,
                                                                color: '#bfbfbf',
                                                              }}
                                                            />
                                                          </Skeleton.Node></div> : 
                  <Bar 
                      options={{
                        scales: {
                          x: { display: false },
                          y: {
                            grid: {
                              display: false
                            }
                          }
                        },
                        indexAxis: 'y',
                        elements: {
                          bar: {
                            borderWidth: 2,
                          },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          title: {
                            display: true,
                            text: 'Users per source',
                          },
                          legend: {display: false},
                          datalabels: {
                            display: true,
                            color: "black",
                            formatter: (value, ctx) => {return (value).toLocaleString('en-US')},
                            anchor: "center",
                            offset: 16,
                            align: "end",
                            font: {
                              size: 14
                            },
                          }
                        },
                      }} 
                      data={getSourceChartData(articleTrafficSourceUsersData, articleTrafficSourceUsersLabels)}
                      type='bar'
                      height={300}
                    />}
              </StyledCard>
              </Col>
              <Col span={12}>
              <StyledCard 
                title='How did readers read this?'
                style={{marginLeft: 18}}
                extra={<Extra query_obj={encodeURIComponent(JSON.stringify(getExploreArticlePlatformUnionQuery(articleID, dateRange)))} />}
              >
                  {renderArticlePlatformUnion.isLoading ? <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                            <PieChartOutlined
                                                              style={{
                                                                fontSize: 40,
                                                                color: '#bfbfbf',
                                                              }}
                                                            />
                                                          </Skeleton.Node></div>
                  : 
                  <Pie
                    type="pie"
                    data={getPlatformChartData(articlePlatformUsersData, articlePlatformUsersLabels)}
                    height={300}
                    options={
                        {
                          ...commonOptions,
                          maintainAspectRatio: false,
                          responsive: true,
                        plugins: {
                            legend: {
                              position: "right",
                              align: "middle",
                          },
                          datalabels: {
                            formatter: (value, ctx) => {
                              const datapoints = ctx.chart.data.datasets[0].data
                              const total = datapoints.reduce((total, datapoint) => total + Number(datapoint), 0)
                              const percentage = (value / total) * 100;
                              return percentage.toFixed(1) + "%";
                            },
                            display: (context) => {
                              return Number(getPlatformChartData(articlePlatformUsersData, articlePlatformUsersLabels).datasets[0].data[context.dataIndex]) >= findSecondLargest(getPlatformChartData(articlePlatformUsersData, articlePlatformUsersLabels).datasets[0].data.map(d => Number(d)));
                            },
                            font: {
                              size: 16
                            },
                            color: '#000',
                          },
                          title: {
                            display: true,
                            text: 'Users per platform',
                            size: 18
                          },
                        },
                        scales: {
                          x: { display: false },
                          y: { display: false },
                        }
                    }}
                    // onClick={getElementAtEvent}
                  />}
              </StyledCard>
              <StyledCard 
                title='How did it perform since publication?'
                style={{marginLeft: 18}}
                extra={<Extra query_obj={encodeURIComponent(JSON.stringify(getExploreArticleDailyPageviewsQuery(articleID, dateRange)))} />}
              >
                  {renderArticleDailyPageviews.isLoading ? <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Skeleton.Node active>
                                                            <AreaChartOutlined
                                                              style={{
                                                                fontSize: 40,
                                                                color: '#bfbfbf',
                                                              }}
                                                            />
                                                          </Skeleton.Node></div> : 
                  <Line
                    type="area"
                    data={getDailyPageviewsChartData(articleDailyPageviewsData, articleDailyPageviewsLabels)}
                    height={300}
                    options={{
                      ...commonOptions,
                      scales: {
                        ...commonOptions.scales,
                        y: {
                          stacked: false,
                          beginAtZero: true
                        },
                      },
                      plugins: {
                        datalabels: {
                          display: false
                        },
                        ...commonOptions.plugins
                      },
                    }}
                    // onClick={getElementAtEvent}
                  />}
              </StyledCard>
              </Col>
          </Row>
        </Col>}
        </QueryContext>
  );
}