import { BrowserRouter, Switch, Route } from 'react-router-dom';

import App from './App';
import { ExplorePage, LoginPage, OauthRedirectPage, NoMatch, PipelinePage, PipelinesSummaryPage, ArticleDashboardPage, AudienceBuilderPage, CohortRetentionChart } from './pages';
import { SecurityContextProvider } from './components/SecurityContext/SecurityContextProvider';
import PrivateRoute from './components/PrivateRoute';
import { createRoot } from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter basename='/app'>
      <SecurityContextProvider>
        <App>
          <Switch>
            <PrivateRoute key="explore" path="/" exact >
              <ExplorePage />
            </PrivateRoute>
            <PrivateRoute key="audience" path="/audience" exact >
              <AudienceBuilderPage />
            </PrivateRoute>
            <PrivateRoute key="cohort" path="/dashboards/cohort" >
              <CohortRetentionChart />
            </PrivateRoute>
            <PrivateRoute key="pipeline" path="/pipeline" >
              <PipelinePage />
            </PrivateRoute>
            <PrivateRoute key="pipelines" path="/pipelines" >
              <PipelinesSummaryPage />
            </PrivateRoute>
            <PrivateRoute key="article" path="/dashboards/article" >
              <ArticleDashboardPage />
            </PrivateRoute>
            <Route key="login" path="/login">
              <LoginPage />
            </Route>
            <Route key="oauthcallback" path="/oauth/complete">
              <OauthRedirectPage />
            </Route>
            <Route path="*">
              <NoMatch />
          </Route>
          </Switch>
        </App>
      </SecurityContextProvider>
  </BrowserRouter>,
  // eslint-disable-next-line no-undef
);