import React, { useEffect, useState } from 'react';
import { backendApiUrl, dataEngIDs } from '../config';
import { useSecurityContext } from '../hooks';
import { Space, Table, Tag, Skeleton, Descriptions, Typography, Button, Collapse, Select, notification, Avatar, Tooltip, DatePicker, Input, Col, Row } from 'antd';
import { Card, Button as CustomButton, Alert} from '../atoms';
import { AddNewDataSourceModal } from '../components/DashboardPipeline/AddNewDataSourceModal';
import { EditOutlined, CheckOutlined, CopyOutlined, DeleteTwoTone, UserOutlined, CheckCircleTwoTone, CloseCircleTwoTone, FolderOpenOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import PrismCode from '../components/ChartContainer/PrismCode';
import jwtDecode from 'jwt-decode';
import { copyToClipboard } from '../utils';
import { useHistory } from 'react-router';
import moment from 'moment';

const { Title } = Typography;
const { Panel } = Collapse;
const { Search } = Input;



const Link = styled.a`
&& {
    &:hover {
        opacity: 0.8;
        textDecoration: 'underline';
      }
}
`;


export function PipelinePage() {
  
  const [dashboard, setDashboard] = useState({status: '', user_name: 'a'});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [status, setStatus] = useState('in dev');
  const [searchedText, setSearchedText] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const { currentToken } = useSecurityContext();
  const location = useLocation();
  const { push } = useHistory();

  const getDashboardFromDb = () => {
    setIsLoading(true)
    const pathSplitted = location.pathname.split('/');
    const dashboardId = pathSplitted[pathSplitted.length -1];
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/dashboard/${dashboardId}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            data['queries'] = 'queries' in data ? data['queries'] : [];
            setIsLoading(false)
            setDashboard(data)
            setStatus(data.status)
            setStartDate(data.start_date)
        });
  }

  useEffect(() => {
    getDashboardFromDb();
  }, [status])


  const handleUnlink = (record) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          title: dashboard.title,
          status: dashboard.status,
          type: dashboard.type,
          url: dashboard.url,
          start_date: moment(dashboard.start_date).utc().format('YYYY-MM-DDTHH:mm:ss'),
          queries: dashboard.queries.map(query => query.id).filter(queryId => queryId !== record.id),
      })
   }
    fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
      .then(response => response.json())
      .then(data => getDashboardFromDb());
  }
  

  const columns = [
    {
      title: 'Table Name',
      dataIndex: 'bq_table_name',
      key: 'bq_table_name',
      render: (text, record) => `cube_reporting.${text}`,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['bq_table_name', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Query',
      key: 'query_obj',
      dataIndex: 'query_obj',
      render: (_, { query_obj }) => (
        <>
          <PrismCode code={JSON.stringify(JSON.parse(query_obj), null, 2)} />
          <Space layout="horizontal">
            <Button
              data-testid="copy-cube-query-btn"
              icon={<CopyOutlined />}
              size="small"
              onClick={async () => {
                await copyToClipboard(JSON.stringify(JSON.parse(query_obj), null, 2));
              }}
              type="primary"
            >
              Copy to Clipboard
            </Button>
            <Link target='_blank' rel='noopener noreferrer' style={{textDecoration: 'underline'}} href={`https://exodus-cube.scmp.com/app/?query=${query_obj}`}>
            <Button
              data-testid="copy-cube-query-btn"
              icon={<FolderOpenOutlined />}
              size="small"
              type="primary"
            >
              Open in Explore
            </Button>
            </Link>
          </Space>
        </>
      ),
    },
    {
      title: 'Partition Name',
      dataIndex: 'partition_name',
      key: 'partition_name',
    },
    {
      title: 'Owner',
      dataIndex: 'user_id',
      key: 'owner',
      render: (text, record) => <Tooltip title={record.user_name}>
                                {record.user_photo ? <Avatar 
                                  src={<img src={record.user_photo} referrerPolicy="no-referrer"/>} 
                                  size="large" 
                                  icon={<UserOutlined />} 
                                /> : <Avatar size="large">{record.user_name[0].toUpperCase()}</Avatar>}
                                </Tooltip>,
      filteredValue: [searchedText],
      onFilter: (value, record) => ['bq_table_name', 'user_name'].map(key => record[key].toLowerCase().includes(value.toLowerCase())).includes(true),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (text) => moment(text).format('llll')
    },
    {
      title: '',
      dataIndex: 'remove',
      key: 'remove',
      render: (text, record) => <Button 
                                  type="link" 
                                  danger 
                                  onClick={() => handleUnlink(record)} 
                                  disabled={dashboard.status === 'in prod'}
                                >
                                unlink
                                </Button>
    }
  ];

  const openNewDataSourceModal = () => {
    setIsModalOpen(true);
  }

  const handleClickIconEdit = () => {
    setToggleEdit(!toggleEdit)
  }

  const handleStatusChange = (value) => {
    setStatus(value);
    setToggleEdit(!toggleEdit)
    if (value !== dashboard.status){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
        body: JSON.stringify({
            title: dashboard.title,
            status: value,
            type: dashboard.type,
            url: dashboard.url,
            start_date: moment(dashboard.start_date).utc().format('YYYY-MM-DDTHH:mm:ss'),
            queries: dashboard.queries.map(query => query.id),
        })
     }
      fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
        .then(response => response.json());
      if (value === 'in prod'){
        // generate dag
        onGenerateDAG();
      }
    }
  }

  const handleDashboardDelete = async () => {
    setIsDeleteLoading(true)
    let requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          title: dashboard.title,
          status: dashboard.status,
          type: dashboard.type,
          url: dashboard.url,
          start_date: moment(dashboard.start_date).utc().format('YYYY-MM-DDTHH:mm:ss'),
          queries: [],
      })
    }
    await fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
      .then(response => response.json());

    requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    }
    fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
      .then(response => setIsDeleteLoading(false))
      .then(data => notification.open({
        message: `${dashboard.title} successfully deleted!`,
        icon: <DeleteTwoTone />,
      }))
      .then(response => push('/pipelines'));
  }

  const onStartDateOk = (value) => {
    setStartDate(value)
    let requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          title: dashboard.title,
          status: dashboard.status,
          type: dashboard.type,
          url: dashboard.url,
          queries: dashboard.queries.map(query => query.id),
          start_date: value.utc().format('YYYY-MM-DDTHH:mm:ss')
      })
    }
    fetch(`${backendApiUrl}/dashboard/${dashboard.id}`, requestOptions)
      .then(response => response.json());
  }

  const onGenerateDAG = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
      body: JSON.stringify({
          dashboard_id: dashboard.id,
      })
   }
    fetch(`${backendApiUrl}/dags`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if ('message' in data){
          notification.open({
            message: `DAG ${dashboard.title.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '_')} successfully created!`,
            icon: <CheckCircleTwoTone />,
          })
        } else {
          notification.open({
            message: `DAG ${dashboard.title.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '_')} creation failed!`,
            description: JSON.stringify(data),
            icon: <CloseCircleTwoTone />,
            style: {width: '600px'},
            duration: 30
          })
        }
      })
      .catch(err => console.log(err));
  }


  return (
    <Card>
      <Space direction='vertical'>
        <Title>{dashboard.title}</Title>
        {isLoading ? <Skeleton active/> : <Descriptions title="">
          <Descriptions.Item label="Owner">
            <Tooltip title={dashboard.user_name} placement="right">
              {dashboard.user_photo ? <Avatar 
                src={<img src={dashboard.user_photo} referrerPolicy="no-referrer"/>} 
                size="large" 
                icon={<UserOutlined />} 
                style={{marginTop: -8}}
              /> : <Avatar size="large" style={{marginTop: -8}}>{dashboard.user_name[0].toUpperCase()}</Avatar>}
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Date Added">{moment(dashboard.created).format('llll')}</Descriptions.Item>
          <Descriptions.Item label="Last Updated">{moment(dashboard.updated).format('llll')}</Descriptions.Item>
          <Descriptions.Item label="Url">
            <Link target='_blank' rel='noopener noreferrer' href={dashboard.url}>{dashboard.url}</Link>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Space style={{
              display: 'flex',
              marginTop: -5
            }}>
              {
                  !toggleEdit ? <Tag color={status === 'in dev' ? 'purple' : status === 'in prod' ? 'green' : 'cyan'} key={status}>
                  {status.toUpperCase()}
                </Tag>: 
                <Select 
                  defaultValue={status}
                  style={{
                    width: 200,
                  }} 
                  options={[{value: 'in dev', label: 'IN DEV'}, {value: 'in prod', label: 'IN PROD'}]}
                  onChange={handleStatusChange}
                />
              }
              <Button 
                  shape="circle" 
                  icon={!toggleEdit ? <EditOutlined /> : <CheckOutlined />} 
                  onClick={handleClickIconEdit}
                />
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="Pipeline scheduled start date">
            {dashboard.status === 'in dev' ? <DatePicker 
              style={{marginTop: -4}} 
              defaultValue={moment(startDate)} 
              showTime={{format: 'HH:mm'}} 
              format='YYYY-MM-DD HH:mm' 
              onOk={onStartDateOk} 
              showNow={false}
              allowClear={false}
            /> : <p>{moment(dashboard.start_date).format('YYYY-MM-DDTHH:mm')}</p>}
          </Descriptions.Item>
          {dashboard.status === 'in prod' && <Descriptions.Item label="Airflow dag">
            <Link 
              target='_blank' 
              rel='noopener noreferrer' 
              href={`https://1f9d99c392ea4c528a1dc8f693d83ccc-dot-us-central1.composer.googleusercontent.com/dags/${dashboard.title.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '_')}`}
            >
              {`https://1f9d99c392ea4c528a1dc8f693d83ccc-dot-us-central1.composer.googleusercontent.com/dags/${dashboard.title.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '_')}`}
            </Link>
          </Descriptions.Item>}
        </Descriptions>}
      </Space>
      <Space 
        direction="vertical" 
        size="middle"
        style={{
          display: 'flex',
          paddingTop: 40
        }}
      >
        <Row justify='center'>
          <Col flex="520px" >
          {dashboard.status === 'in dev' ? 
            <Alert message="👆 Switch status to IN PROD when you want to deploy your pipeline" type="info" closable/>
          : <Alert message="👆 Switch status to IN DEV if you want to add a table" type="info" closable/>}
          </Col>
        </Row>
        <Space 
          direction="horizontal" 
          size="middle"
          style={{
            display: 'flex',
            paddingBottom: 20
          }}
        >
          
          <CustomButton 
            type="primary" 
            style={{marginTop: 16}} 
            onClick={openNewDataSourceModal}
            disabled={dashboard.status === 'in prod'}
          >
            Add Table
          </CustomButton>
          
          {/* {dataEngIDs.includes(jwtDecode(currentToken).sub) && dashboard.status !== 'in dev' && 
            <CustomButton type="secondary" style={{marginTop: 16}} onClick={onGenerateDAGClick}>Generate DAG</CustomButton>
          } */}
        </Space>
        <Search 
          placeholder='Search on Table Name or Owner Name...' 
          style={{margin:'auto', width: '20%'}}
          onSearch={(value) => setSearchedText(value)}
          onChange={(e) => setSearchedText(e.target.value)}
        ></Search>
        <Table 
          bordered
          columns={columns} 
          dataSource={dashboard.queries} 
          loading={isLoading}
        />
        {dashboard.user_name === jwtDecode(currentToken).user_email.split('@')[0] && <Collapse ghost style={{marginTop: 60}}>
          <Panel header="Advanced" key="1">
            <Alert 
              message="Remove this dashboard" 
              description={<Space direction="vertical">
                <span>
                  This action removes the dashboard record from Exodus backend (it does not remove it from Looker Studio! 😌)
                </span>
                <Button onClick={handleDashboardDelete} type="danger" loading={isDeleteLoading}>
                  Delete
                </Button></Space>}
              type="error" 
            />
          </Panel>
        </Collapse>}
      </Space>
      <AddNewDataSourceModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} getDashboardFromDb={getDashboardFromDb} dashboard={dashboard}/>
    </Card>
  );
}