import { Dropdown, Avatar } from 'antd';
import  { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { googleLogout } from '@react-oauth/google';
import { useSecurityContext } from '../../hooks';


export function UserAvatar() {

    const { savedGoogleFullRes, removeCubeToken, removeGoogleRefreshRes, removeGoogleFullRes } = useSecurityContext()
    
    const logout = () => {
        googleLogout();
        removeCubeToken();
        removeGoogleRefreshRes();
        removeGoogleFullRes();
    }


    const items = [
        {
          label: <a onClick={logout}><span><LogoutOutlined style={{marginRight: 8}}/>Log out</span></a>,
          key: '0',
        },
      ];
    
    

    return (<Dropdown
          menu={{items}}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <div onClick={e => e.preventDefault()} style={{cursor: 'pointer'}}>
            <Avatar 
                src={<img src={savedGoogleFullRes.picture} referrerPolicy="no-referrer"/>} 
                size="large" 
                icon={<UserOutlined />} 
            />
          </div>
    </Dropdown>)
}