import { bfs, convertEdgelistToAdjlist, swapCubes } from "./utils";

export const playgroundContext = {
    isCloud: false,
    baseUrl: process.env.REACT_APP_CUBEJS_API_BASE_URL,
    token: null
  }

export function buildApiUrl(
    apiUrl,
  ) {
    return `${apiUrl}/v1`;
  }


export const glossaryURL = 'https://data-scmp.notion.site/85f05ab3bc2d4870b64652ec5028cc01?v=0252d66bd95243cd93e4adb865e944b5';

export const backendApiUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const globallyAllowedGA3Cubes = [
  'GA', 
  'Drupal', 
  'Loyalty', 
  'Readership', 
  // 'SalesforceSubscriptions',
  'DrupalTopics',
  'DrupalArticleImages',
  'Comments',
  'DrupalVideos',
  'Videos',
  'RegisteredUsers', 
  'CampaignsUnionUA',
  'Subscriptions',
];

export const globallyAllowedGA4Cubes = [
  'Events', 
  'DrupalGA4',
  'UsersAttributes',
  'DrupalTopicsGA4',
  'DrupalVideosGA4',
  'DrupalArticleImagesGA4',
  'CommentsGA4',
  'RegisteredUsers', 
  'CampaignsUnion',
  'Subscriptions',
  'NewsletterDetails',
  'NewsletterEvents'
];

export const globallyAllowedGA4AppleCubes = [
  'GA4AppleUnion', 
  'DrupalGA4Apple',
  'DrupalTopicsGA4',
];

export const globallyAllowedGA3AppleCubes = [
  'UAAppleUnion', 
  'DrupalUAApple',
  'DrupalTopics',
];

export const globallyAllowedOtherCubes = [
  'CubeQueries',
]

export const globallyAllowedGA4Reporting = [
  'ManagementEvents',
  'ManagementDrupal',
  'ManagementUsersAttributes',
  'ManagementSubscriptions',
  'ReportingAggregate',
]

export const globallyAllowedGA3Reporting = [
  'ManagementGA',
  'ManagementDrupal',
  'ManagementRegisteredUsers',
  'ManagementLoyalty',
  'ManagementSubscriptions'
]


export const defaultTimeDimensions = {
  'Google Analytics 4': {dimension: 'Events.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1}, 
  'Google Analytics': {dimension: 'GA.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1}, 
  'GA4 Management Reports': {dimension: 'ManagementEvents.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1}, 
  'GA Management Reports': {dimension: 'ManagementGA.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1},
  'Drupal': {dimension: 'Drupal.publication_date', dateRange: 'All time', allowedInDimensionsDropdown: true, priority: 3},
  'Drupal (GA + Apple News)': {dimension: 'DrupalUAApple.publication_date', dateRange: 'All time', allowedInDimensionsDropdown: true, priority: 3},
  'Drupal (GA4)': {dimension: 'DrupalGA4.publication_date', dateRange: 'All time', allowedInDimensionsDropdown: true, priority: 3},
  'Drupal (GA4 + Apple News)': {dimension: 'DrupalGA4Apple.publication_date', dateRange: 'All time', allowedInDimensionsDropdown: true, priority: 3},
  'Drupal Management Reports': {dimension: 'ManagementDrupal.publication_date', dateRange: 'All time', allowedInDimensionsDropdown: true, priority: 3},
  'Subscriptions': {dimension: 'Subscriptions.active_date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 2},
  'Management Subscriptions': {dimension: 'ManagementSubscriptions.active_date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 2},
  'Users': {dimension: 'RegisteredUsers.registration_date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 2},
  'Management Users': {dimension: 'ManagementRegisteredUsers.registration_date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 2},
  // 'Salesforce Subscriptions': {dimension: 'SalesforceSubscriptions.active_date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 1},
  'Comments': {dimension: 'GA.date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 3},
  'Comments (GA4)': {dimension: 'Events.date', dateRange: 'All time', allowedInDimensionsDropdown: false, priority: 3},
  'Campaigns': {dimension: 'Events.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1}, 
  'Google Analytics 4 + Apple News': {dimension: 'GA4AppleUnion.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1},
  'Google Analytics + Apple News': {dimension: 'UAAppleUnion.date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 1}, 
  'Cube Logs': {dimension: 'CubeQueries.publish_date', dateRange: 'Today', allowedInDimensionsDropdown: true, priority: 1}, 
  'Newsletters': {dimension: 'NewsletterEvents.newsletter_action_date', dateRange: 'Today', allowedInDimensionsDropdown: false, priority: 2}
};


export const noChartDimensions = [
  // UA
  'Drupal.title', 
  'Drupal.social_headline', 
  'Drupal.article_id', 
  'Drupal.publication_time',
  'DrupalVideos.video_id',
  'Videos.video_youtube_id',
  'DrupalUAApple.title', 
  'DrupalUAApple.social_headline', 
  'DrupalUAApple.article_id', 
  'DrupalUAApple.publication_time', 
  'GA.event_action', 
  'GA.url', 
  'GA.next_page',
  'GA.previous_page',
  'GA.cd_campaign',
  'GA.campaign',
  'GA.full_visitor_id',
  'RegisteredUsers.user_id',
  'ManagementRegisteredUsers.user_id',
  'DrupalArticleImages.url',
  // GA4
  'DrupalGA4.title', 
  'DrupalGA4.social_headline', 
  'DrupalGA4.article_id', 
  'DrupalGA4.publication_time',
  'DrupalGA4Apple.title', 
  'DrupalGA4Apple.social_headline', 
  'DrupalGA4Apple.article_id', 
  'DrupalGA4Apple.publication_time',
  'DrupalSeries.series_id',
  'DrupalSeriesGA4.series_id',
  'Events.url',
  'Events.user_pseudo_id',
  'Events.campaign',
  'Events.next_page',
  'Events.previous_page',
  'Events.source_medium',
  'Events.next_page_article_id',
  'Events.previous_page_article_id',
  // GA4 + Apple
  'GA4AppleUnion.url',
  'GA4AppleUnion.user_pseudo_id',
  'GA4AppleUnion.campaign',
  // GA4 + Apple
  'UAAppleUnion.url',
  'UAAppleUnion.user_pseudo_id',
  'UAAppleUnion.campaign',
  // Cube Logs
  'CubeQueries.query',
  // Reporting
  'ManagementDrupal.title', 
  'ManagementDrupal.social_headline', 
  'ManagementDrupal.article_id', 
  'ManagementDrupal.publication_time', 
  'ManagementEvents.url',
  'ManagementEvents.user_pseudo_id',
  'ManagementEvents.campaign',
  'ManagementEvents.next_page',
  'ManagementEvents.previous_page',
  'ManagementGA.url',
  'ManagementGA.user_pseudo_id',
  'ManagementGA.campaign',
  'ManagementGA.next_page',
  'ManagementGA.previous_page',
  // Newsletter
  'NewsletterDetails.newsletter_name',
  'NewsletterDetails.newsletter_campaign_name',
  'NewsletterDetails.newsletter_journey_name',
  'NewsletterDetails.newsletter_subject',
  'NewsletterDetails.newsletter_group_name',
];

export const excludingFilters = [
  'GA.country_management_report',
  'Drupal.url',
  'DrupalGA4.url',
  'DrupalUAApple.url',
  'DrupalGA4Apple.url',
  'ManagementDrupal.url',
  'Drupal.articles_with_traffic',
  'DrupalUAApple.articles_with_traffic',
  'DrupalGA4.articles_with_traffic',
  'DrupalGA4Apple.articles_with_traffic',
  'ManagementDrupal.articles_with_traffic',
  'RegisteredUsers.first_subscription_date',
  'RegisteredUsers.last_visit_date',
  'RegisteredUsers.first_visit_date',
  'Subscriptions.subs_start_date',
  'Subscriptions.subs_end_date',
  'ManagementSubscriptions.subs_start_date',
  'ManagementSubscriptions.subs_end_date',
  'CampaignsUnion.date',
  'GA.loyal_regular_users_day',
  'GA.loyal_regular_users_week',
  'GA.loyal_regular_users_month',
  'GA.loyal_regular_users_quarter',
  'GA.loyal_regular_users__ru',
  'GA.loyal_regular_users__ru_day',
  'GA.loyal_regular_users__ru_week',
  'GA.loyal_regular_users__ru_month',
  'GA.loyal_regular_users__ru_quarter',
  'Events.loyal_regular_users_day',
  'Events.loyal_regular_users_week',
  'Events.loyal_regular_users_month',
  'Events.loyal_regular_users_quarter',
  'Events.loyal_regular_users__ru',
  'Events.loyal_regular_users__ru_day',
  'Events.loyal_regular_users__ru_week',
  'Events.loyal_regular_users__ru_month',
  'Events.loyal_regular_users__ru_quarter',
  'Events.loyal_regular_users_all_events_day',
  'Events.loyal_regular_users_all_events_week',
  'Events.loyal_regular_users_all_events_month',
  'Events.loyal_regular_users_all_events_quarter',
  'Events.loyal_regular_users__ru_all_events',
  'Events.loyal_regular_users__ru_all_events_day',
  'Events.loyal_regular_users__ru_all_events_week',
  'Events.loyal_regular_users__ru_all_events_month',
  'Events.loyal_regular_users__ru_all_events_quarter',
  'Events.users_all_events',
  'Events.loggedin_users_all_events',
  'Events.active_registered_users_all_events',
  'Events.active_subscribers_all_events',
  'Events.loyal_regular_users_all_events',
  'ManagementEvents.users_all_events',
  'ManagementEvents.loggedin_users_all_events',
  'ManagementEvents.active_registered_users_all_events',
  'ManagementEvents.active_subscribers_all_events',
  'ManagementEvents.loyal_regular_users_all_events',
  'ManagementGA.loyal_regular_users_day',
  'ManagementGA.loyal_regular_users_week',
  'ManagementGA.loyal_regular_users_month',
  'ManagementGA.loyal_regular_users_quarter',
  'ManagementEvents.loyal_regular_users_day',
  'ManagementEvents.loyal_regular_users_week',
  'ManagementEvents.loyal_regular_users_month',
  'ManagementEvents.loyal_regular_users_quarter',
  'ManagementEvents.loyal_regular_users__ru',
  'ManagementEvents.loyal_regular_users__ru_day',
  'ManagementEvents.loyal_regular_users__ru_week',
  'ManagementEvents.loyal_regular_users__ru_month',
  'ManagementEvents.loyal_regular_users__ru_quarter',
  'Subscriptions.last_touch_subscribers',
  'CommentsGA4.country',
  'CommentsGA4.traffic_hour',
  'CommentsGA4.traffic_weekday',
  'Comments.country',
  'Comments.traffic_hour',
  'Comments.traffic_weekday',
  'Events.is_paid_gme_campaign',
  'ManagementEvents.is_paid_gme_campaign',
  'CampaignsUnion.loyal_regular_users_day',
  'CampaignsUnion.loyal_regular_users_week',
  'CampaignsUnion.loyal_regular_users_month',
  'CampaignsUnion.loyal_regular_users_quarter',
  'CampaignsUnionUA.loyal_regular_users_day',
  'CampaignsUnionUA.loyal_regular_users_week',
  'CampaignsUnionUA.loyal_regular_users_month',
  'CampaignsUnionUA.loyal_regular_users_quarter',
]

export const excludingTimeDimensions = [
  'CampaignsUnion.date',
  'Comments.created_date',
  'CommentsGA4.created_date'
]

export const excludingDimensions = excludingFilters.concat([
  'Drupal.article_content', 
  'DrupalUAApple.article_content', 
  'DrupalGA4.article_content', 
  'DrupalGA4Apple.article_content', 
]);

export const excludingPreDefinedFilters = [
  'GA.no_style',
  'GA.section_is_not_null_and_relevant',
  'GA.platform_is_not_offplatform',
  'ManagementGA.no_style',
  'ManagementGA.section_is_not_null_and_relevant',
  'ManagementGA.platform_is_not_offplatform',
  'Events.no_style',
  'Events.section_is_not_null_and_relevant',
  'Events.platform_is_not_offplatform',
  'ManagementEvents.no_style',
  'ManagementEvents.section_is_not_null_and_relevant',
  'ManagementEvents.platform_is_not_offplatform',
  'GA4AppleUnion.no_style',
  'GA4AppleUnion.section_is_not_null_and_relevant',
  'GA4AppleUnion.platform_is_not_offplatform',
  'UAAppleUnion.no_style',
  'UAAppleUnion.section_is_not_null_and_relevant',
  'UAAppleUnion.platform_is_not_offplatform',
  'GA.last_three_days',
  'Loyalty.loyalty_is_not_null',
  'Events.last_touch_article_is_evergreen',
  'ManagementSubscriptions.last_touch_article_is_evergreen',
];

export const dataEngIDs = [
  '110697811280797631899', '115589877206424129461'
];

export const hourFields = [
  'GA.traffic_hour',
  'Events.traffic_hour',
  'Events.traffic_minutes',
  'ManagementEvents.traffic_hour',
  'ManagementGA.traffic_hour',
  'Drupal.publication_hour',
  'DrupalGA4.publication_hour',
  'DrupalUAApple.publication_hour',
  'DrupalGA4Apple.publication_hour',
  'ManagementDrupal.publication_hour',
]


// helper functions for query builder

const getGloballyAllowedCubes = (meta, GASchema, mode) => {
  return meta &&  meta.length > 0 && GASchema === 'GA4' && ['audience', 'query', 'retention'].includes(mode) ? globallyAllowedGA4Cubes 
  : meta &&  meta.length > 0 && GASchema === 'GA4 + Apple' && mode === 'query' ? globallyAllowedGA4AppleCubes
  : meta &&  meta.length > 0 && GASchema === 'UA + Apple' && mode === 'query' ? globallyAllowedGA3AppleCubes
  : meta && meta.length > 0 && GASchema ===  'UA' && mode === 'query' ? globallyAllowedGA3Cubes
  : meta && meta.length > 0 && GASchema ===  'UA' && mode === 'reporting' ? globallyAllowedGA3Reporting
  : meta && meta.length > 0 && GASchema ===  'GA4' && mode === 'reporting' ? globallyAllowedGA4Reporting
  : globallyAllowedOtherCubes
}

const getMapOfRelatedCubesFromMeta = (GASchema, mode, meta) => {
  const globallyAllowedCubes = getGloballyAllowedCubes(meta, GASchema, mode);
  const metaFiltered = meta &&  meta.length  > 0 ? meta.filter(cube => globallyAllowedCubes.includes(cube.name)) : [];
  // Build pairs based on meta joins
  const pairs = [];
  metaFiltered.forEach(cube => {
    cube.joins?.filter(join => globallyAllowedCubes.includes(join.name)).forEach(joinCube => {
      pairs.push([cube.name, joinCube.name])
    })
  })

  // Transform a list of joins into an adjacency list to apply a BFS graph algo to find these "islands" or connected cubes
  // We directly map from cube to its view as we apply the BFS algo
  const groups = [];
  const visited = {};
  let v, cubeGroup;

  const adjlist = convertEdgelistToAdjlist(pairs);
  for (v in adjlist) {
    if (adjlist.hasOwnProperty(v) && !visited[v]) {
      cubeGroup = bfs(v, adjlist, visited).map(cube => getViewFromCube(metaFiltered, cube))
      groups.push([...new Set(cubeGroup)]);
    }
  }
  if (groups.length === 0){
    // means no joins at all in this schema, need to add the only Cube joinable to itself
    groups.push([getViewFromCube(metaFiltered, globallyAllowedCubes[0])])
  }

  let nodeMaps = {};
  for (const cubeGroup of groups) {
    for (const elementCube of cubeGroup){
      nodeMaps[elementCube] = cubeGroup;
    }
  }

  return nodeMaps;
}

export const getReportingsFromMeta = (meta) => {
  const metaFiltered = meta &&  meta.length  > 0 ? meta.filter(cube => globallyAllowedGA4Reporting.includes(cube.name)) : [];
  const reports = {};
  metaFiltered.forEach(cube => {
    if('measures' in cube){
      cube.measures.forEach(measure => {
        if ('meta' in measure && 'isReporting' in measure.meta){
          reports[measure.meta.isReporting] = measure.meta.isReporting in reports ? [...reports[measure.meta.isReporting], measure.name] : [measure.name]
        }
      })
    }
  })
  return reports
}


export const getViewFromCube = (meta, cubeName) => {
  // WORKS
  // const globallyAllowedCubes = meta &&  meta.length  > 0 && meta.map(cube => cube.name).includes('Events') ? globallyAllowedGA4Cubes : globallyAllowedGA3Cubes;
  const globallyAllowedCubes = globallyAllowedGA3Cubes.concat(globallyAllowedGA4Cubes).concat(globallyAllowedGA4AppleCubes)
                                                      .concat(globallyAllowedGA3AppleCubes).concat(globallyAllowedOtherCubes)
                                                      .concat(globallyAllowedGA3Reporting).concat(globallyAllowedGA4Reporting);
  if (
    globallyAllowedCubes.includes(cubeName) 
    && meta && meta.length > 0 
    &&  meta.filter(cube => cube.name === cubeName).length > 0
    && 'meta' in meta.filter(cube => cube.name === cubeName)[0].dimensions[0]
  ) {
    return meta.filter(cube => cube.name === cubeName)[0].dimensions[0].meta.view;
  }
  return null
}


export const getAuthorisedDeptFromCube = (meta, cubeName) => {
  // WORKS
  // const globallyAllowedCubes = meta &&  meta.length  > 0 && meta.map(cube => cube.name).includes('Events') ? globallyAllowedGA4Cubes : globallyAllowedGA3Cubes;
  const globallyAllowedCubes = globallyAllowedGA3Cubes.concat(globallyAllowedGA4Cubes).concat(globallyAllowedGA4AppleCubes)
                                                      .concat(globallyAllowedGA3AppleCubes).concat(globallyAllowedOtherCubes)
                                                      .concat(globallyAllowedGA3Reporting).concat(globallyAllowedGA4Reporting);
  if (
    globallyAllowedCubes.includes(cubeName) 
    && meta && meta.length > 0 
    &&  meta.filter(cube => cube.name === cubeName).length > 0
    && 'meta' in meta.filter(cube => cube.name === cubeName)[0].dimensions[0]
  ) {
    return meta.filter(cube => cube.name === cubeName)[0].dimensions[0].meta.department;
  }
  return null
}


export const getArrayOfCubesFromQuery = (query, excludeTimeDimensions=false) => {
  // parse dimensions
  let dimensionsCubes = [], measuresCubes = [], segmentsCubes = [], timeDimensionsCubes = [], filtersCubes = [];
  if('dimensions' in query && query.dimensions.length > 0){
    dimensionsCubes = query.dimensions.map(dim => dim.split('.')[0])
    dimensionsCubes = [...new Set(dimensionsCubes)];
  }
  // parse measures
  if('measures' in query && query.measures.length > 0){
    measuresCubes = query.measures.map(mea => mea.split('.')[0])
    measuresCubes = [...new Set(measuresCubes)];
  }
  // parse timeDimensions
  if(!excludeTimeDimensions && 'timeDimensions' in query && query.timeDimensions.length > 0){
    timeDimensionsCubes = query.timeDimensions.map(tdim => tdim.dimension.split('.')[0])
    timeDimensionsCubes = [...new Set(timeDimensionsCubes)];
  }
  // parse segments
  if('segments' in query && query.segments.length > 0){
    segmentsCubes = query.segments.map(seg => seg.split('.')[0])
    segmentsCubes = [...new Set(segmentsCubes)];
  }
  // parse filters
  if('filters' in query && query.filters.length > 0){
    filtersCubes = query.filters.map(fil => fil.member.split('.')[0])
    filtersCubes = [...new Set(filtersCubes)];
  }

  return [...new Set([...dimensionsCubes, ...measuresCubes, ...timeDimensionsCubes, ...segmentsCubes, ...filtersCubes])]
}


export const getArrayOfViewsFromArrayOfCubes = (cubeArray, meta) => {
  return [...new Set(cubeArray.map(name => getViewFromCube(meta, name)))]
}


export const contextuallyAllowedCubes = (GASchema, mode, metaExtended, query) => {
  const globallyAllowedCubes = getGloballyAllowedCubes(metaExtended, GASchema, mode);
  const mapOfRelatedCubes = getMapOfRelatedCubesFromMeta(GASchema, mode, metaExtended)
  const queryCubes = getArrayOfViewsFromArrayOfCubes(getArrayOfCubesFromQuery(query), metaExtended)
  return queryCubes.length > 0 ? 
    [...new Set(queryCubes.map(queryCube => mapOfRelatedCubes[queryCube]).flat())]
    : globallyAllowedCubes.map(cube => getViewFromCube(metaExtended, cube));
}


export const filterMembers = (GASchema, mode, department, userName, availableMembers, selectedMembers, metaExtended, query, type) => {
  const useDAUNestedAggMembers = 'measures' in query && query.measures.some(measure => ['Events.avg_dau', 'Events.avg_rl_dau'].includes(measure));
  const useNLNestedAggMembers = 'measures' in query && query.measures.some(measure => ['NewsletterEvents.email_open_rate', 'NewsletterEvents.email_click_through_rate', 'NewsletterEvents.email_click_to_open_rate'].includes(measure)) && 
  !('dimensions' in query && query.dimensions.map(dim => dim.split('.')[1]).includes('newsletter_campaign_name'));
  const useAudience = 'segments' in query && query.segments.some(seg => seg.includes('audience__'));
  return availableMembers.filter(member => contextuallyAllowedCubes(GASchema, mode, metaExtended, query).includes(getViewFromCube(metaExtended, member.cubeName))).map(member => {
    const timeDimension = defaultTimeDimensions[getViewFromCube(metaExtended, member.cubeName)];
    if (type === 'measure'){
      member.members = member.members.filter(mem => !selectedMembers.map(selected => selected.name).includes(mem.name) 
        && !(timeDimension && timeDimension.dimension === mem.name && !timeDimension.allowedInDimensionsDropdown)
        && !excludingDimensions.includes(mem.name)
        && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
        && (useDAUNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`EventsAggregate`) : true)
        && (useNLNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`NewslettersAggregate`) : true)
      )
      return member
    } 
    if (type === 'dimension'){
      member.members = mode === 'audience' ? member.members.filter(mem => !selectedMembers.map(selected => selected.name).includes(mem.name)
                                                              && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
                                                              && !(timeDimension && timeDimension.dimension === mem.name && !timeDimension.allowedInDimensionsDropdown)
                                                                  )
                                                           .filter(mem => ['RegisteredUsers.user_id', 'Events.user_pseudo_id'].includes(mem.name))

      : member.members.filter(mem => !selectedMembers.map(selected => selected.name).includes(mem.name) 
        && !(timeDimension && timeDimension.dimension === mem.name && !timeDimension.allowedInDimensionsDropdown)
        && !excludingDimensions.includes(mem.name)
        && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
        && (
          useDAUNestedAggMembers && 
          ('dimensions' in query && query.dimensions.length > 0
          || 'filters' in query && query.filters.length > 0) ? false 
          : useDAUNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`EventsAggregate`) : true
          )
        && (
          useNLNestedAggMembers && 
          ('dimensions' in query && query.dimensions.length > 0
          || 'filters' in query && query.filters.length > 0) ? false 
          : useNLNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`NewslettersAggregate`) : true
          )
      )
      return member
    } 
    if(type === 'time') {
      member.members = member.members.filter(mem => !selectedMembers.map(selected => selected.dimension.name).includes(mem.name) && !excludingTimeDimensions.includes(mem.name)
        && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
        && (useDAUNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`EventsAggregate`) : true)
        && (useNLNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`NewslettersAggregate`) : true)
        && (mode === 'retention' ? mem.name === 'Events.date': true)
      )
      return member
    }
    if (type === 'filter'){
      member.members = member.members.filter(mem => !(timeDimension && timeDimension.dimension === mem.name) && !excludingFilters.includes(mem.name)
        && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
        && (
          useDAUNestedAggMembers && 
          ('dimensions' in query && query.dimensions.length > 0
          || 'filters' in query && query.filters.length > 0) ? false 
          : useDAUNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`EventsAggregate`) : true
          )
        && (
          useNLNestedAggMembers && 
          ('dimensions' in query && query.dimensions.length > 0
          || 'filters' in query && query.filters.length > 0) ? false 
          : useNLNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`NewslettersAggregate`) : true
          )
      )
      return member
    }
    if (type === 'segment'){
      member.members = member.members.filter(mem => !selectedMembers.map(selected => selected.name).includes(mem.name) && !excludingPreDefinedFilters.includes(mem.name)
        && (mem.name.includes('audience__') ? (mem.name.includes(`audience__${userName?.replace('.','_')}`) || (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))) : true)
        // && (mem.meta?.department.includes(department) || mem.meta?.department.includes('Company'))
        && (useDAUNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`EventsAggregate`) : true)
        && (useNLNestedAggMembers ? 'authorisedWithCubes' in mem.meta && mem.meta?.authorisedWithCubes.includes(`NewslettersAggregate`) : true)
        && (useAudience && mode !== 'retention' ? !('view' in mem.meta && mem.meta.view.includes('My Audiences')) : true)
        && (mode === 'retention' && selectedMembers.length < 5 ? ('view' in mem.meta && mem.meta.view.includes('My Audiences')) 
                    : 'retention' && selectedMembers.length >= 5 ? false : true)
      )
      return member
    }
  })
}

// adding this function to regroup cube members by view indicated in the meta property of each cube member
export const getMembersByView = (GASchema, mode, cubeArray) => {
  const globallyAllowedCubes = getGloballyAllowedCubes(cubeArray, GASchema, mode);
  const views = [...new Set(cubeArray.filter(cube => globallyAllowedCubes.includes(cube.cubeName)).flatMap(cube => cube.members.map(member => member.meta.view)))];
  const membersByView = views.map(view => {
    const members = cubeArray.filter(cube => globallyAllowedCubes.includes(cube.cubeName)).flatMap(cube => cube.members.filter(member => member.meta.view === view));
    return { view, members };
  });
  return membersByView;
}


export const platformBEtoFE = {
  'mobile web': `Mobile Web`,
  desktop: `Desktop`,
  offplatform: `Off Platform`,
  'mobile app': `Mobile App`,
  'Google AMP': `Google AMP`,
  'Apple News': `Apple News`,
}

export const basicGASchemaToGAcube = {
  'UA + Apple': 'UAAppleUnion',
  'UA': 'GA',
  'GA4 + Apple': 'GA4AppleUnion',
  'GA4': 'Events'
}

export const basicGASchemaToDrupalCube = {
  'UA + Apple': 'DrupalUAApple',
  'UA': 'Drupal',
  'GA4 + Apple': 'DrupalGA4Apple',
  'GA4': 'DrupalGA4'
}

export const advancedGASchemaToGAcube = {
  'UA + Apple': 'GA',
  'UA': 'GA',
  'GA4 + Apple': 'Events',
  'GA4': 'Events'
}

export const advancedGASchemaToDrupalCube = {
  'UA + Apple': 'Drupal',
  'UA': 'Drupal',
  'GA4 + Apple': 'DrupalGA4',
  'GA4': 'DrupalGA4'
}

export const fromTrafficToLastTouch = (cubeMember, GASchema) => {
  const gaCube = basicGASchemaToGAcube[GASchema]
  const loyaltyCube = GASchema === "GA4" ? 'UsersAttributes' : 'Loyalty';
  const map =  {
    [`${gaCube}.country`]: 'userSubscriptions.country_at_subscription',
    [`${loyaltyCube}.loyalty`]: 'userSubscriptions.loyalty_at_subscription',
    [`${gaCube}.campaign`]: 'userSubscriptions.last_touch_campaign',
    [`${gaCube}.utm_content`]: 'userSubscriptions.last_touch_content',
    [`${gaCube}.channel_grouping`]: 'userSubscriptions.last_touch_channel_grouping',
    [`${gaCube}.source_medium`]: 'userSubscriptions.last_touch_source_medium',
    [`${gaCube}.platform`]: 'userSubscriptions.last_touch_platform',
    [`${gaCube}.off_platform`]: 'userSubscriptions.last_touch_off_platform',
    [`${gaCube}.user_type`]: 'userSubscriptions.last_touch_user_type',
    [`${gaCube}.cd_campaign`]: 'userSubscriptions.last_touch_cd_campaign',
    [`${gaCube}.cd_module`]: 'userSubscriptions.last_touch_cd_module',
    [`${gaCube}.cd_pgtype`]: 'userSubscriptions.last_touch_cd_pgtype',
    [`${gaCube}.ad_group_name`]: 'userSubscriptions.last_touch_channel_grouping',
    [`${gaCube}.traffic_source`]: 'userSubscriptions.last_touch_traffic_source',
    [`${gaCube}.traffic_type`]: 'userSubscriptions.last_touch_traffic_type',
    'Subscriptions.subscription_plan': 'userSubscriptions.subscription_plan',
    'Subscriptions.piano_pomo_code': 'userSubscriptions.last_promo_code_used',
  }
  if(Object.keys(map).includes(cubeMember)){
    return map[cubeMember]
  } else return cubeMember
}

export const recommendedQueriesBasic = (GASchema) => {
  const gaCube = basicGASchemaToGAcube[GASchema];
  const drupalCube = basicGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Daily active users',
    query: {
      measures: [`${gaCube}.users`], 
      timeDimensions: [{dimension: `${gaCube}.date`, granularity: 'day', dateRange: 'Last 30 days'}]
    },
    dateRange: 'past 30 days'
  },
  {
    title: 'Top articles - ranked by Regular & Loyal Users',
    query: {
      measures: [`${gaCube}.loyal_regular_users`, `${gaCube}.users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.authors_name`, `${drupalCube}.first_topic`, `${drupalCube}.first_user_needs`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 7 days'},{dimension: `${drupalCube}.publication_date`}]
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Top topics',
    query: {
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`, `${gaCube}.active_subscribers`], 
      dimensions: [`${drupalCube}.first_topic`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 7 days'}, {dimension: `${drupalCube}.publication_date`}]
    },
    entity: 'topic',
    dateRange: 'past 7 days'
  },
  {
    title: 'Active users per traffic source',
    query: {
      measures: [`${gaCube}.users`], 
      dimensions: [`${gaCube}.traffic_source`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 7 days'}]
    },
    dateRange: 'past 7 days'
  },
  {
    title: 'Active users per country',
    query: {
      measures: [`${gaCube}.users`], 
      dimensions: [`${gaCube}.country`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 7 days'}]
    },
    dateRange: 'past 7 days'
  },
  {
    title: 'Average DAU per desk',
    query: {
      measures: [`${gaCube}.avg_dau`, `${gaCube}.avg_rl_dau`], 
      dimensions: [`${drupalCube}.desk`], 
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: 'Last 7 days'}],
    },
    entity: 'desk',
    dateRange: 'past 7 days'
  },
]};

export const recommendedQueriesAdvanced = (GASchema) => {
  const gaCube = advancedGASchemaToGAcube[GASchema];
  const drupalCube = advancedGASchemaToDrupalCube[GASchema];
  return [
  {
    title: 'Subscriptions: top last touch articles',
    query: {
      measures: [`Subscriptions.new_subscribers`], 
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.authors_name`, `${drupalCube}.first_topic`, `${drupalCube}.first_user_needs`], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Last 7 days'},{dimension: `${drupalCube}.publication_date`}],
    },
    schema: 'GA',
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'Social media: top articles clicked',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${gaCube}.traffic_type`, operator: "equals", values: ["Social"]},
        {member: `${gaCube}.previous_page`, operator: "equals", values: ["entrance"]}
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${gaCube}.traffic_source`],
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`]
    },
    entity: 'article',
    dateRange: 'past 7 days'
  },
  {
    title: 'SCMP homepage: top articles clicked',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`}],
      filters: [
        {member: `${gaCube}.previous_page_category`, operator: "equals", values: ["Homepage"]}
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.authors_name`, `${drupalCube}.first_topic`],
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Weekly new subscribers',
    query: {
      measures: [`Subscriptions.new_subscribers`], 
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Last 13 weeks', granularity: 'week'}],
    },
    schema: 'GA',
    dateRange: 'past 13 weeks'
  },
  {
    title: 'New subscribers per country',
    query: {
      measures: [`Subscriptions.new_subscribers`], 
      dimensions: [`RegisteredUsers.country_at_subscription`],
      timeDimensions: [{dimension: `Subscriptions.active_date`, dateRange: 'Last 7 days'}, {dimension: "RegisteredUsers.registration_date"}],
    },
    schema: 'GA',
    dateRange: 'past 7 days'
  },
  {
    title: 'Recirculation: top articles clicked',
    query: {
      timeDimensions: [{dimension: `${gaCube}.date`, dateRange: "Last 7 days"}, {dimension: `${drupalCube}.publication_date`}],
      segments: [
        `${gaCube}.pageview_is_from_recirculation`
      ],
      dimensions: [`${drupalCube}.article_id`, `${drupalCube}.title`, `${drupalCube}.publication_date`, `${drupalCube}.authors_name`, `${drupalCube}.first_topic`],
      measures: [`${gaCube}.users`, `${gaCube}.pageviews`]
    },
    entity: 'article',
    schema: 'GA',
    dateRange: 'past 7 days'
  },
]};

export const frontendifyQuery = (query) => {
  // query comes from saved query or pasted URL in browser
  // need to transform Apple + GA --> GA
  query = swapCubes(query, 'DrupalGA4Apple', 'DrupalGA4')
  query = swapCubes(query, 'DrupalUAApple', 'Drupal')
  query = swapCubes(query, 'GA4AppleUnion', 'Events')
  query = swapCubes(query, 'UAAppleUnion', 'GA')
  return query
}

export const defaultAudienceQuery = {dimensions: ["RegisteredUsers.user_id"], timeDimensions: [{dimension: "RegisteredUsers.registration_date"}]};